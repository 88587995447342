@import './Variables.scss';

@font-face {
  font-family: 'Allianz';
  src: url('/fonts/AllianzNeoW01-Light.eot?#iefix');
  src: url('/fonts/AllianzNeo-Light.eot?#iefix') format('eot'),
    url('/fonts/AllianzNeo-Light.woff2') format('woff2'),
    url('/fonts/AllianzNeo-Light.woff') format('woff'),
    url('/fonts/AllianzNeo-Light.ttf') format('truetype'),
    url('/fonts/AllianzNeo-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

//GENERAL

body {
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: $Allianz;
  background-color: map-get($theme-colors, 'background');
}
.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
// CONT SHADOW - CARD

.card {
  &.login-card {
    max-width: 600px;
    .card-header {
      background-color: map-get($theme-colors, 'white');
      border: none;
      text-align: center;
      padding-top: 30px;
      .card-title {
        font-size: 20px;
        color: map-get($theme-colors, 'darkborder');
        font-weight: 500;
        span {
          color: map-get($theme-colors, 'black');
          font-weight: 800;
        }
      }
    }
    .card-body {
      padding-right: 40px !important;
      padding-left: 40px !important;
      .btn {
        min-width: 200px;
      }
    }
  }
}

.card {
  background-color: map-get($theme-colors, 'white');
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  border: none;
  overflow: hidden;
  margin-bottom: 32px;
  .card-header {
    background-color: map-get($theme-colors, 'primary');
    border: 0;
    .card-title {
      margin-bottom: 0;
      color: map-get($theme-colors, 'white');
      font-weight: 700;
    }
  }
  .card-body {
    padding: 32px 16px;
  }
  .card-footer {
    background-color: map-get($theme-colors, 'grey');
    border-top: 1px solid map-get($theme-colors, 'darkborder');
    padding-top: 24px;
    padding-bottom: 24px;
    color: map-get($theme-colors, 'darkborder');
    .row {
      .col-6 {
        &:first-child {
          font-weight: 700;
        }
        &:last-child {
          a {
            color: map-get($theme-colors, 'primary');
          }
        }
      }
    }
  }
}

.cont-white-shadow {
  background-color: map-get($theme-colors, 'white');
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 15px;
  border-radius: 20px;
}

// REACT-DATE PICKER

.react-datepicker-wrapper {
  input {
    padding: 6px 12px;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

.react-datepicker {
  border: 1px solid map-get($theme-colors, 'border');
  font-family: $Allianz;
  .react-datepicker__triangle {
    border-bottom-color: map-get($theme-colors, 'border');
  }
  .react-datepicker__header {
    border-bottom: 1px solid map-get($theme-colors, 'border');
    .react-datepicker__current-month {
      color: map-get($theme-colors, 'primary-contrast');
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: map-get($theme-colors, 'black');
      }
    }
  }
}

.react-datepicker__day {
  color: map-get($theme-colors, 'black') !important;
  &:hover {
    border-radius: 50%;
  }
}

.react-datepicker__day--today {
  border-radius: 50%;
}

.react-datepicker__day--selected {
  background-color: map-get($theme-colors, 'primary-contrast') !important;
  color: map-get($theme-colors, 'white') !important;
  border-radius: 50%;
}
