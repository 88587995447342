.custom-file:disabled {
  background-color: grey;
}

.upload-btn {
  background-color: #2768a7;
}

.upload-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
}

.button-container {
  margin-left: 15px;
  align-self: center;
}
